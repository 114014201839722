export const CategoryType = {
  Navigation: "Navigation",
  Axios: "Axios",
  OtherButtonAction: "OtherButtonAction",
  FormSubmit: "FormSubmit",
  FormValidationError: "FormValidationError",
  UnClassifedError: "UnClassifedError",
  AxiosError: "AxiosError",
  AxiosWithRejectMessage: "AxiosWithRejectMessage",
} as const;

export const OtherButtonActionType = {
  Dashboard_Apply_Filter: "Dashboard_Apply_Filter",
  Patient_Preview_Report: "Patient_Preview_Report",
  Patient_Report_AE: "Patient_Report_AE",
  Patient_Toggle_Treatment_Status: "Patient_Toggle_Treatment_Status",
  Patient_Send_SMS: "Patient_Send_SMS",
  Patient_Table_Filter: "Patient_Table_Filter",
  Patient_CST_VA_GRAPH_FILTER_DATA: "Patient_CST_VA_GRAPH_FILTER_DATA",
  Patient_CST_VA_GRAPH_FILTER_PERIOD: "Patient_CST_VA_GRAPH_FILTER_PERIOD",
  User_Send_Registration_Email: "User_Send_Registration_Email",
  User_Send_Registration_SMS: "User_Send_Registration_SMS",
  User_Send_ForgotPassword_Email: "User_Send_ForgotPassword_Email",
  Save_Product_Details: "Save_Product_Details",
  Scan_Product: "Scan_Product",
  Download_rdp_report: "Download_rdp_report"
} as const;

export const SubtleType = {
  Add: "Add",
  Edit: "Edit",
} as const;

export const NavigationActionType = {
  AppBar_AvatarAction: "AppBar_AvatarAction",
  Popover_UpdateProfile: "Popover_UpdateProfile",
  SideBar_Dashboard: "SideBar_Dashboard",
  SideBar_Patients: "SideBar_Patients",
  SideBar_PTIReference: "SideBar_PTIReference",
  SideBar_Disclaimer: "SideBar_Disclaimer",
  SideBar_HCPs: "SideBar_HCPs",
  SideBar_AuditLog: "SideBar_AuditLog",
  SideBar_ProductDetails: "SideBar_ProductDetails",
  SideBar_RWDReport: "SideBar_RWDReport",
  SideBar_PcsTranslate: "SideBar_PcsTranslate",
  SideBar_AddHcpEntries:"SideBae_AddHcpEntry",
  Login_ForgotPassword: "Login_ForgotPassword",
  Login_Registration: "Login_Registration",
  PTIRef_Tab1: "PTIRef_Tab1",
  PTIRef_Tab2: "PTIRef_Tab2",
  PTIRef_Tab3: "PTIRef_Tab3",
  Dashboard_FilterPopover: "Dashboard_FilterPopover",
  Patients_ViewDetails: "Patients_ViewDetails",
  Logout: "Logout",
} as const;

export const ModalViewType = {
  DeleteHCPModal: "Modal/DeleteHCPModal",
  PatientModal: "Modal/PatientModal",
  DeletePatientModal: "Modal/DeletePatientModal",
  SimpleModal: "Modal/SimpleModal",
  CSTAndVAGraphModal: "Modal/CSTAndVAGraphModal",
  OctImagesModal: "Modal/OctImagesModal",
  TreatmentRecordModal: "Modal/TreatmentRecordModal",
  AEModal: "Modal/AEModal",
  ConfirmPasswordModal: "Modal/ConfirmPasswordModal",
  ShareAccessModal: "Modal/ShareAccessModal"
} as const;

export const FormType = {
  ChangePasswordForm: "ChangePasswordForm",
  ForgotPasswordForm: "ForgotPasswordForm",
  PatientForm: "PatientForm",
  LoginForm: "LoginForm",
  TreatmentInfoForm: "TreatmentInfoForm",
  CreateAccountForm: "CreateAccountForm",
  UpdateUserProfileForm: "UpdateUserProfileForm",
  HCPLogSerchForm: "HCPLogSerchForm",
  PatientSendSMSForm: "PatientSendSMSForm",
} as const;
