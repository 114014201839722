// refer to backend paths for update
const ROUTE_MAIN_PATHS = {
  user: "/api/user",
  patient: "/api/patient",
  dashboard: "/api/dashboard",
  treatmentRecord: "/api/treatmentRecords",
  hcp: "/api/hcp",
  itadmin: "/api/itadmin",
} as const;

const USER_ROUTE_MINOR_PATHS = {
  userCreate: "/create",
  userLogin: "/login",
  userLogout: "/logout",
  userGetUserData: "/getUserData",
  userEditUserData: "/editUserData",
  userUpdateHcpInstitute: "/updateHcpInstitute",
  userUploadAvatar: "/uploadAvatar",
  userDeleteAvatar: "/deleteAvatar",
  userSendEmailRegisterCode: "/sendEmailRegisterCode",
  userVerifyEmailRegisterCode: "/verifyEmailRegisterCode",
  userGetInstituteInfo: "/getHcpInstitute",
  userSendSMSRegisterCode: "/sendSMSRegisterCode",
  userVerifySMSRegisterCode: "/verifySMSRegisterCode",
  userSendEmailForgotPasswordCode: "/sendEmailForgotPasswordCode",
  userVerifyEmailForgotPasswordCode: "/verifyEmailForgotPasswordCode",
  userChangePassword: "/changePassword",
  userResetForgotPassword: "/resetForgotPassword",
  userGetDBConstants: "/getDBConstants",
  userPosologyReferenceImages: "/posologyReferenceImages",
  userUpdatePSPStatus: "/userPspStatus",
} as const;

const PATIENT_MINOR_PATHS = {
  patientGetPatients: "/getPatients",
  patientAddPatient: "/addPatient",
  patientUpdatePatient: "/updatePatient",
  patientGetPatientDetails: "/getPatientDetails",
  patientDeletePatient: "/patientDeletePatient",
  patientUpdateFixedTreatmentWeeks: "/updateFixedTreatmentWeeks",
  patientUpdateTreatmentPhaseId: "/updateTreatmentPhaseId",
  patientToggleEyeTreatmentStatus: "/toggleEyeTreatmentStatus",
  patientSendAuthSMS: "/sendAuthSMS",
  patientGetPatientEncryptData: "/getPatientEncryptData",
  patientPostPatientEncryptData: "/postPatientEncryptData",
  patientPostCaregiverEncryptData: "/postCgEncryptData",
  patientCheckCgConsent: "/checkCgConsent",
  patientCheckPatientConsent: "/checkPatientConsent",
  patientCheckPatientExist: "/checkPatientExist",
  patientEditCheck: "/editCheck",
  updateEditCheck: "/updateEdit",
  caregiverSMS: "/sendCareGiverLinks",
} as const;

const TREATMENT_RECORD_MINOR_PATHS = {
  treatmentRecordAdd: "/add",
  treatmentRecordUpdate: "/update",
  getOctImages: "/getOctImages",
  toggleTreatmentStatus: "/toggleTreatmentStatus",
  reportAE: "/reportAE",
  getProductDetailsFromScan: "/getProductDetailsFromScan",
} as const;

const HCP_MINOR_PATHS = {
  hcpGetHcps: "/getHCPs",
  hcpDeleteHcps: "/deleteHCP",
  hcpDownloadHcpLog: "/downloadHcpLog",
  itadminGetReports: "/downloadReports",
  getItAdmin: "/getitadmin",
  requestPatientAccess: "/requestPatientAccess",
  getHcpByInstitute: "/getHcpByInstitute",
  addEditor: "/addEditor",
  revokeEditAccess: "/revokeEditAccess",
} as const;

const IT_ADMIN_MINOR_PATHS = {
  saveProductDetails: "/saveProductDetails",
  addHCP: "/addHcpDetails",
  getAllHCp: "/getAllHcpDetails",
  deleteProfile: "/deleteHcpDetails",
  getAllTranslations: "/getAllTranslations",
  addTranslation: "/addTranslation",
  deleteTranslation: "/deleteTranslation",
  updateTranslation: "/updateTranslation",
  getTranslation: "/getTranslation",
  exportToExcel: "/exportToExcel"
} as const;

const DASHBOARD_MINOR_PATHS = {
  dashboardGetPatientByDiseaseType: "/patientByDiseaseType",
  dashboardGetEyesByTreatmentInterval: "/eyesByTreatmentInterval",
  dashboardGetVisualActivityOutcome: "/visualActivityOutcome",
  dashboardGetDrugComparisonByVA: "/drugComparisonByVA",
  dashboardGetEyesByStatus: "/eyesByStatus",
} as const;

const generateFullPaths = <T extends { [key in string]: string }>(
  minorPaths: T,
  mainPath: string
): { [key in keyof T]: T[key] } =>
  Object.entries(minorPaths).reduce(
    (fullPaths, [key, path]) => ({
      ...fullPaths,
      [key]: mainPath + path,
    }),
    {} as { [key in keyof typeof minorPaths]: typeof minorPaths[key] }
  );

const USER_FULL_PATHS = generateFullPaths(
  USER_ROUTE_MINOR_PATHS,
  ROUTE_MAIN_PATHS.user
);

const PATIENT_FULL_PATHS = generateFullPaths(
  PATIENT_MINOR_PATHS,
  ROUTE_MAIN_PATHS.patient
);

const TREATMENT_RECORD_FULL_PATHS = generateFullPaths(
  TREATMENT_RECORD_MINOR_PATHS,
  ROUTE_MAIN_PATHS.treatmentRecord
);

const DASHBOARD_FULL_PATHS = generateFullPaths(
  DASHBOARD_MINOR_PATHS,
  ROUTE_MAIN_PATHS.dashboard
);

const HCP_FULL_PATHS = generateFullPaths(HCP_MINOR_PATHS, ROUTE_MAIN_PATHS.hcp);

const IT_ADMIN_FULL_PATHS = generateFullPaths(
  IT_ADMIN_MINOR_PATHS,
  ROUTE_MAIN_PATHS.itadmin
);

export const API_ROUTE_FULL_PATHS = {
  ...USER_FULL_PATHS,
  ...PATIENT_FULL_PATHS,
  ...TREATMENT_RECORD_FULL_PATHS,
  ...HCP_FULL_PATHS,
  ...IT_ADMIN_FULL_PATHS,
  ...DASHBOARD_FULL_PATHS,
} as const;
