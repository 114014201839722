export const ROUTE_PATHS = {
  INIT: "/",
  HCPINIT: "/dashboard",
  ADMININIT: "/admin/hcp",
  ADMINITINIT: "itadmin/productentries",
  DASHBOARD: "/dashboard",
  PATIENTS: "/patients",
  PROFILE: "/profile",
  CHANGE_PASSWORD: "/changepassword",
  REFERENCE: "/reference",
  LOGIN: "/login",
  PATIENT_DETAILS: "/patientdetails/:id",
  PATIENT_PREVIEW_DETAILS: "/patientpreview/:id",
  FORGET_PASSWORD: "/forgotpassword",
  REGISTRATION: "/registration",
  ADMIN_HCP_TABLE: "/admin/hcp",
  ADMIN_AUDIT_LOG: "/admin/auditlog",
  CONFIRM_PATIENT_CONSENT: "/patient/confirm/:encryptdata",
  CONFIRM_CAREGIVER_CONSENT: "/careGiver/confirm/:encryptdata",
  IT_ADMIN_PRODUCT_ENTRIES: "itadmin/productentries",
  IT_ADMIN_RWD_REPORT: "itadmin/rwdreport",
  IT_ADMIN_HCP_ENTRIES: "itadmin/addhcpentry",
  IT_ADMIN_PCS_TRANSLATE: "itadmin/pcstranslate",
  INDEX: "*",
  DISCLAIMER: "/disclaimer",
  PSPCONSENTPAGE: "/visioncare",
} as const;
