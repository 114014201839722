export const REACT_QUERY_KEYS = {
  USER_DATA: "userData",
  USER_EMAIL_REGISTER_CODE: "userEmailRegisterCode",
  USER_EMAIL_REGISTER_VERIFY_CODE: "userEmailRegisterVerifyCode",
  USER_SMS_REGISTER_CODE: "userSMSRegisterCode",
  USER_SMS_REGISTER_VERIFY_CODE: "userSMSRegisterVerifyCode",
  USER_EMAIL_FORGOT_PASSWORD_CODE: "userEmailForgotPasswordCode",
  USER_EMAIL_FORGOT_PASSWORD_VERIFY_CODE: "userEmailForgotPasswordVerifyCode",
  USER_CHANGE_PASSWORD: "userChangePassword",
  USER_RESET_FORGOT_PASSWORD: "userResetForgotPassword",
  USER_INSTITUTE_INFO: "userInstituteInfo",
  PATIENTS: "patients",
  HCPS: "hcps",
  IT_ADMINS: "itAdmins",
  PATIENT: "patient",
  DB_CONSTANTS: "dBConstants",
  DRUGCOMPARISONCHART: "Drug-Comparison-Chart",
  EYESSTATUSCHART: "Eyes-Status-Chart",
  EYESTREATMENTINTERVALCHART: "Eyes-Treatment-Interval-Chart",
  PATIENTDISEASETYPECHART: "Patient-DiseaseType-Chart",
  VISUALACTIVITYOUTCOMECHART: "Visual-Activity-Outcome-Chart",
  PATIENT_ENCRYPT_DATA: "Patient_Encrypt_Data",
  POSOLOGY_REFERENCE_IMAGE: "Posology_Reference_Image",
  PCS_TRANSLATE: "pcsTranslate"
} as const;
